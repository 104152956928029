import { getCssVariable } from '../helpers'

/**
 * Set container in wrapper to full window width
 */
export const toFullWidthInWrapper = (container = '.full-width-in-wrapper') => {
  const run = () => {
    const _containers: NodeListOf<HTMLElement> = document.querySelectorAll(container)
    const wrapperWidth = getCssVariable('container-width')

    if (_containers.length && wrapperWidth) {
      const windowWidth = window.innerWidth
      const isPercentWrapper = wrapperWidth.includes('%') ? true : false

      _containers.forEach(container => {
        if (!isPercentWrapper) {
          container.style.width = `${windowWidth}px`
          container.style.marginLeft = `${(windowWidth - parseInt(wrapperWidth)) / -2}px`
        }

        if (isPercentWrapper) {
          const sizeWrapper = parseInt(getCssVariable('--container-width'))
          container.style.width = `${windowWidth + sizeWrapper}px`
          container.style.marginLeft = `${(windowWidth - sizeWrapper / 2) / -2}px`
        }
      })
    }
  }

  run()
  window.addEventListener('resize', run)
}
