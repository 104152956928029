import CoreAnimation from '../animations/CoreAnimations'

export default class {
  toggledButton: HTMLElement
  container: HTMLElement

  constructor() {
    this.toggledButton = <HTMLElement>document.getElementById('toggle')
    this.container = <HTMLElement>document.getElementById('site-navigation')
  }

  closeMenu() {
    console.log(this.container)
    const links = this.container.querySelectorAll('li:not(.menu-item-has-children)')

    links.forEach(e => {
      e.addEventListener('click', () => {
        this.container.classList.remove('open')
        this.toggledButton.classList.remove('open')
        CoreAnimation.slideToggle(this.container)
      })
    })
  }

  addSticky() {
    const header = document.getElementById('masthead')

    header && this.toggledButton.addEventListener('click', () => header.classList.add('sticky'))
  }
}
