interface DOMElements {
    button: HTMLElement
}

export class ScrollToTop {
    private DOM: DOMElements

    constructor() {
        this.DOM = {
            button: document.querySelector('#scroll-to-top') as HTMLElement,
        }

        this.DOM.button.addEventListener('click', this.scrollToTop)
    }

    scrollToTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
        })
    }
}
