export default class {
  container: string

  constructor() {
    this.container = '.form-row input, .form-row textarea'
    this.init()
  }

  init() {
    this.getContainer.forEach(el => {
      if (el.getAttribute('placeholder') == null) {
        el.value != '' && this.addActive(el)
        el.addEventListener('focus', e => this.onFocus(<HTMLInputElement>e.target))
        el.addEventListener('keypress', e => this.onKeyPress(<HTMLInputElement>e.target))
        el.addEventListener('blur', e => this.onBlur(<HTMLInputElement>e.target))
      }
    })
  }

  get getContainer() {
    const container: NodeListOf<HTMLInputElement> = document.querySelectorAll(this.container)
    return container
  }

  addActive(input: HTMLInputElement) {
    const parent = input.closest('.form-row')
    parent && parent.classList.add('active')
  }

  removeActive(input: HTMLInputElement) {
    const parent = input.closest('.form-row')
    parent && parent.classList.remove('active')
  }

  onFocus(input: HTMLInputElement) {
    this.addActive(input)
  }

  onKeyPress(input: HTMLInputElement) {
    if (input.value !== '') {
      this.addActive(input)
    }
  }

  onBlur(input: HTMLInputElement) {
    if (input.value === '') {
      this.removeActive(input)
    }
  }
}
