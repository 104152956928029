import { smoothScroll } from './js/modules/scroll'
import SiteHeader from './js/modules/SiteHeader/SiteHeader'
import MainNav from './js/modules/MainNavigation'
import FloatLabels from './js/modules/FloatLabels'
import MobileMenu from './js/modules/MobileMenu'
import { ScrollToTop } from './js/modules/ScrollToTop'

import 'normalize.css'
import './sass/style.scss'
import { toFullWidthInWrapper } from './js/utils/utils'
import { WindowWidth } from './js/utils/WindowWidth'
import './../node_modules/fslightbox/index.js'


window.addEventListener('load', () => {
  new ScrollToTop()
  new SiteHeader()
  new FloatLabels()
  const Menu = new MainNav()


  smoothScroll()
  new WindowWidth()
  new MobileMenu()


  toFullWidthInWrapper()
})
