export default class {
  container: HTMLElement
  siteHeader: HTMLElement

  constructor() {
    this.container = <HTMLElement>document.getElementById('mobile-menu')
    this.siteHeader = <HTMLElement>document.getElementById('masthead')
    this.init()
  }

  init() {
    this.toggleMenu()
  }

  toggleMenu() {
    const html = <HTMLElement>document.querySelector('html')
    const humburgerToOpen = document.getElementById('toggle')
    const humburgerToClose = document.querySelector('#mobile-menu #toggle')

    if (humburgerToClose) {
      humburgerToClose.addEventListener('click', () => {
        this.container.classList.remove('open')
        document.body.classList.remove('mobile-menu-is-open')
        html.style.overflowY = 'auto'
      })
    }

    if (humburgerToOpen) {
      humburgerToOpen.addEventListener('click', () => {
        this.container.classList.add('open')
        document.body.classList.add('mobile-menu-is-open')
        html.style.overflowY = 'hidden'
      })
    }
  }
}
