import { IdefaultSettings } from './SiteHeader.d'
import { getCoords } from '../../helpers'

export default class {
  defaultSettings: IdefaultSettings
  siteHeader: HTMLElement

  constructor(
    defaultSettings: IdefaultSettings = {
      stickyHeader: true,
    }
  ) {
    this.defaultSettings = defaultSettings
    this.siteHeader = <HTMLElement>document.getElementById('masthead')
    this.init()
  }

  init() {
    if (this.siteHeader) {
      this.defaultSettings.stickyHeader === false || this.stickyHeader()
    }
  }

  stickyHeader() {

    window.addEventListener('scroll', () => {
      let headerOffsetTop = window.pageYOffset;

      if (headerOffsetTop > 50) {
        this.siteHeader.classList.add('site-header--is-scroll');
      }
      if (headerOffsetTop == 0) {
        this.siteHeader.classList.remove('site-header--is-scroll');
      }
    })
  }
}