import SmoothScroll from 'smooth-scroll'

/**
 * @link https://www.npmjs.com/package/smooth-scroll
 */
export const smoothScroll = () =>
  new SmoothScroll('a[href*="#"]', {
    ignore: '[data-vc-container]',
    header: '#masthead'
  })
