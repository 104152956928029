export class WindowWidth {
  /**
   * Adds the "--document-width" variable to the body tag with
   * the width of the browser without a scrollbar.
   */
  constructor() {
    window.addEventListener('resize', WindowWidth.calculate)
    window.addEventListener('load', WindowWidth.calculate)
    WindowWidth.calculate()
  }

  static calculate() {
    document.body.style.setProperty('--document-width', `${document.body.clientWidth}px`)
  }
}
