export const getScreenWidth = () => {
  const w = window
  const e = document.documentElement
  const g = document.getElementsByTagName('body')[0]
  const width: number = w.innerWidth || e.clientWidth || g.clientWidth

  return width
}

export const getScreenHeight = () => {
  const w = window
  const e = document.documentElement
  const g = document.getElementsByTagName('body')[0]
  const height: number = w.innerHeight || e.clientHeight || g.clientHeight

  return height
}

export const isMobile = () => {
  if (getScreenWidth() <= 768) return true
}

export const isPhone = () => {
  if (getScreenWidth() <= 440) return true
}

export const getCoords = (elem: HTMLElement) => {
  const box = elem.getBoundingClientRect()

  const body = document.body
  const docEl = document.documentElement

  const scrollTop = window.pageYOffset || docEl.scrollTop || body.scrollTop
  const scrollLeft = window.pageXOffset || docEl.scrollLeft || body.scrollLeft

  const clientTop = docEl.clientTop || body.clientTop || 0
  const clientLeft = docEl.clientLeft || body.clientLeft || 0

  const top = box.top + scrollTop - clientTop
  const left = box.left + scrollLeft - clientLeft

  return {
    top: Math.round(top),
    left: Math.round(left),
  }
}

/**
 *
 * @param options
 * @param url default is used jsData.ajaxUrl
 */
export async function callAjax(options: RequestInit, url = jsData.ajaxUrl) {
  return await fetch(url, options)
}

export const overlay = (callback: Function, appendContainer: HTMLElement | null = null) => {
  appendContainer = appendContainer || document.body

  const overlay = document.createElement('div')
  overlay.classList.add('overlay')
  appendContainer.appendChild(overlay)

  overlay.classList.add('active')
  overlay.addEventListener('click', () => {
    overlay.classList.remove('active')
    callback()
  })
}

export const createLoader = (container: HTMLElement = document.body) => {
  if (container) {
    const loader = document.createElement('div')

    loader.classList.add('loader')
    container == document.body && loader.classList.add('fixed')

    if (window.getComputedStyle(container).position == 'static') container.style.position = 'relative'

    return container.appendChild(loader)
  }
}

export const getCssVariable = (varName: string) => {
  const _varName = varName.startsWith('--') ? varName : `--${varName}`
  const variable = window.getComputedStyle(document.documentElement).getPropertyValue(_varName)
  return variable
}

export const getCssVariableAsNumber = (varName: string) => {
  return parseInt(getCssVariable(varName))
}
